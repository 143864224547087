export const Touchdevice = () => {
  /**
   * ------------------------------------------------------------------------
   * Touch Device用の判定をする
   * ------------------------------------------------------------------------
   */

  const onTouchDevice = 'ontouchstart' in window ? 'true' : 'false';
  document.body.setAttribute('data-touch-device', onTouchDevice);
}
