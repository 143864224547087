export const Switch = () => {
  const targetToggle = Array.from(
    document.querySelectorAll('[data-switch-toggle]'),
  );

  const toggleSwitch = (e) => {
    e.preventDefault();
    const target = e.currentTarget;
    const targetDataAttr = target.getAttribute('aria-controls');
    const containerID = document.getElementById(targetDataAttr);

    if (target.getAttribute('aria-expanded') === 'false') {
      containerID.setAttribute('tabindex', '1');
      target.setAttribute('aria-expanded', 'true');
      containerID.setAttribute('aria-hidden', 'false');
      containerID.focus();
    } else {
      containerID.setAttribute('tabindex', '-1');
      containerID.setAttribute('aria-hidden', 'true');
      target.setAttribute('aria-expanded', 'false');
    }
  };

  targetToggle.forEach((key) => key.addEventListener('click', toggleSwitch));
}
