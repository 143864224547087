export const MegaMenu = () => {
  const targetToggle = Array.from(
    document.querySelectorAll('[data-megamenu-toggle]'),
  );
  const targetClose = Array.from(
    document.querySelectorAll('[data-megamenu-close]'),
  );
  const targetCloseAll = Array.from(
    document.querySelectorAll('[data-megamenu-close-all]'),
  );

  const drawer = document.getElementById('data-drawer');

  const toggleMenu = (e) => {
    e.preventDefault();
    const target = e.currentTarget;
    const targetDataAttr = target.getAttribute('aria-controls');
    const containerID = document.getElementById(targetDataAttr);
    const main = document.getElementById('main');

    if (target.getAttribute('aria-expanded') === 'false') {
      closeMenuAll();
      containerID.setAttribute('tabindex', '1');
      target.setAttribute('aria-expanded', 'true');
      containerID.setAttribute('aria-hidden', 'false');
      containerID.focus();
      drawer.setAttribute('data-drawer', 'true');
      main.setAttribute('aria-hidden', 'true');
    } else {
      closeMenuAll();
    }
  };

  const closeMenu = (e) => {
    e.preventDefault();
    const target = e.currentTarget;
    const targetDataAttr = target.getAttribute('aria-controls');
    const focusID =
      document.querySelector(
        `[data-megamenu-toggle="focus"][aria-controls="${targetDataAttr}"]`,
      ) || null;
    closeMenuAll();
    focusID && focusID.focus();
  };

  const closeMenuAll = () => {
    drawer.setAttribute('data-drawer', 'false');
    const main = document.getElementById('main');
    main.setAttribute('aria-hidden', 'false');

    targetToggle.forEach((item) => {
      item.setAttribute('aria-expanded', 'false');
      const itemId = item.getAttribute('aria-controls');
      const targetId = document.getElementById(itemId);
      targetId && targetId.setAttribute('aria-hidden', 'true');
      targetId && targetId.setAttribute('tabindex', '-1');
    });
  };

  targetToggle.forEach((key) => key.addEventListener('click', toggleMenu));
  targetClose.forEach((key) => key.addEventListener('click', closeMenu));
  targetCloseAll.forEach((key) => key.addEventListener('click', closeMenuAll));
  drawer.addEventListener('click', closeMenuAll);
}
