export const Offcanvas = () => {
  /**
   *  <button type="button" aria-controls="aria-offcanvas" aria-expanded="false" data-toggle-offcanvas>ボタン</button>
   *
   *  <div id="aria-offcanvas" aria-hidden="true" data-offcanvas tabindex="-1">
   *    <div>コンテンツ</div>
   *  </div>
   */

  const targetToggle = Array.from(
    document.querySelectorAll('[data-toggle-offcanvas]'),
  );

  const targetClose = Array.from(
    document.querySelectorAll('[data-close-offcanvas]'),
  );

  const Default = {current_scrollY: null};

  /**
   * 表示、属性やクラスを付ける
   */
  const toggle = (e) => {
    e.preventDefault();
    const target = e.currentTarget;
    const targetDataAttr = target.getAttribute('aria-controls');
    const containerID = document.getElementById(targetDataAttr);

    if (target.getAttribute('aria-expanded') === 'false') {
      containerID.setAttribute('tabindex', '1');
      Default.current_scrollY =
        document.documentElement.scrollTop || document.body.scrollTop;
      document.body.classList.add('is-body-offcanvas-active');
      target.setAttribute('aria-expanded', 'true');
      containerID.setAttribute('aria-hidden', 'false');
      containerID.focus();
    } else {
      containerID.setAttribute('aria-hidden', 'true');
      document.body.setAttribute('style', '');
      document.documentElement.scrollTop = Default.current_scrollY;
      document.body.classList.remove('is-body-offcanvas-active');
      target.setAttribute('aria-expanded', 'false');
      setTimeout(function () {
        containerID.setAttribute('tabindex', '-1');
      }, 300);
    }
  };

  /**
   * aria-offcanvasを初期化
   */
  const hideAll = () => {
    if (document.body.classList.contains('is-body-offcanvas-active')) {
      const controls = document.querySelector('[aria-controls="aria-offcanvas"]');
      controls.setAttribute('aria-expanded', 'false');
      const contents = document.getElementById('aria-offcanvas');
      contents.setAttribute('tabindex', '-1');
      contents.setAttribute('aria-hidden', 'true');
      contents.setAttribute('aria-expanded', 'false');
      document.body.setAttribute('style', '');
      document.documentElement.scrollTop = Default.current_scrollY;
      document.body.classList.remove('is-body-offcanvas-active');
    }
  };

  targetToggle.forEach((key) => key.addEventListener('click', toggle));
  targetClose.forEach((key) => key.addEventListener('click', hideAll));
}
