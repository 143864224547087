export const OffLine = () => {
  /**
   * オフライン時に表示　classはutilitiesに準備済み
   */

  if (!navigator.onLine) {
    const offlineElem = document.createElement('div');
    offlineElem.innerHTML =
      '<div class="is-prompt" data-elements="add-js"><p>Looks like you\'re offline. </p></div>';
    document.body.insertBefore(offlineElem, document.body.firstChild);
  }
}
