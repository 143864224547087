export const Accordion = () => {
  const target = Array.from(document.querySelectorAll('[data-ac-summary]'));

  /**
   * <div data-ac-details>
   *     <h3><a href="">title</a><button data-ac-summary aria-controls="ac1">開く</button></h3>
   *     <ul id="ac1">
   *       <li></li>
   *     </ul>
   * </div>
   */

  const toggleAC = (e) => {
    e.preventDefault();
    const elem = e.currentTarget;
    const targetDataAttr = elem.getAttribute('aria-controls');
    const containerID = document.getElementById(targetDataAttr);
    const wrapper = elem.closest('[data-ac-details]');

    if (wrapper.classList.contains('is-ac-active')) {
      wrapper.classList.remove('is-ac-active');
      elem.setAttribute('aria-expanded', 'false');
      elem.setAttribute('aria-label', 'Open');
      elem.textContent = 'Open';
      containerID.setAttribute('aria-hidden', 'true');
    } else {
      wrapper.classList.add('is-ac-active');
      elem.setAttribute('aria-expanded', 'true');
      elem.setAttribute('aria-label', 'Close');
      elem.textContent = 'Close';
      containerID.setAttribute('aria-hidden', 'false');
      containerID.focus();
    }
    e.stopPropagation();
  };

  target.forEach((key) => key.addEventListener('click', toggleAC));
};
