import {Scroll} from "./_lib/scroll";
import {Touchdevice} from "./_lib/touchdevice";
import {OffLine} from "./_lib/off-line";
import {Offcanvas} from "./_lib/offcanvas";
import {Accordion} from "./_lib/accordion";
import {Tabs} from "./_lib/tabs";
import {Modal} from "./_lib/modal";
import {Switch} from "./_lib/switch";
import {MegaMenu} from "./_lib/mega-menu";

Scroll()
Touchdevice()
OffLine()
Offcanvas()
Accordion()
Tabs()
Modal()
Switch()
MegaMenu()
