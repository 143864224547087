export const Modal = () => {
  const targetOpen = Array.from(document.querySelectorAll('[data-open-modal]'));
  const targetClose = Array.from(document.querySelectorAll('[data-modal-close]'));

  const Default = {current_scrollY: null};

  /**
   * <button type="button" data-open-modal aria-controls="area_modal1" aria-expanded="false" aria-label="開く">モーダル</button>
   * <dialog id="area_modal1" role="dialog" data-modal>
   *  <div role="document">
   *    <div>要素など</div>
   *    <button type="button" data-modal-close role="img" aria-label="Close"></button>
   *  </div>
   *</dialog>
   */
  const showModal = (e) => {
    e.preventDefault();
    const elem = e.currentTarget;
    const id = elem.getAttribute('aria-controls');
    const container = document.getElementById(id);
    container.setAttribute('aria-hidden', 'false');
    container.setAttribute('tabindex', '1');
    container.classList.add('is-modal-active');
    container.showModal();
    container.focus();
    container.addEventListener('click', onBackdropClick);

    Default.current_scrollY =
      document.documentElement.scrollTop || document.body.scrollTop;
    document.body.classList.add('is-body-modal-active');
  };

  /**
   * 属性やクラスを消す
   */
  const closeModal = (e) => {
    const elem = e.currentTarget;
    const container = elem.closest('[data-modal]');
    container.setAttribute('aria-hidden', 'true');
    container.setAttribute('tabindex', '-1');

    document.body.setAttribute('style', '');
    document.documentElement.scrollTop = Default.current_scrollY;
    document.body.classList.remove('is-body-modal-active');

    container.close();
    container.classList.remove('is-modal-active');
  };

  /**
   * 背景クリックで消す
   * */
  const onBackdropClick = (e) => {
    const elem = e.currentTarget;
    const container = elem.closest('[data-modal]');
    const elRect = container.getBoundingClientRect();
    const isInDialog =
      elRect.top <= e.clientY &&
      e.clientY <= elRect.bottom &&
      elRect.left <= e.clientX &&
      e.clientX <= elRect.right;

    // 内側をクリックしていたら、なにもしない
    if (isInDialog) return;
    // 外側をクリックしていたら閉じる
    closeModal(e);
  };

  targetOpen.forEach((key) => key.addEventListener('click', showModal));
  targetClose.forEach((key) => key.addEventListener('click', closeModal));
}
