export const Scroll = () => {
  /**
   * ------------------------------------------------------------------------
   * アップスクロール時、ダウンスクロール時の判定をする
   *
   * 「data-scroll-anker」でaタグアンカーで移動した場合にダウンスクロールに変更
   * <a href="#ank1" data-scroll-anker>アンカー01</a>
   *
   * ------------------------------------------------------------------------
   */

  const header = document.getElementById('header');
  const scrollHead = document.querySelector('[data-scroll-head]');
  let setPosition = 0;
  let scrollStop = false;

  scrollHead &&
  window.addEventListener('scroll', () => {
    document.body.setAttribute('data-scroll-pos-stay', 'false');
    if (setPosition < document.documentElement.scrollTop) {
      document.body.setAttribute('data-scroll-pos', 'is-down');
      scrollHead.style.top = `-${scrollHead.offsetHeight}px`;
    } else {
      document.body.setAttribute('data-scroll-pos', 'is-up');
      scrollHead.style.top = '';
    }
    setPosition = document.documentElement.scrollTop;

    // stay
    clearTimeout(scrollStop);
    scrollStop = setTimeout(function () {
      document.body.setAttribute('data-scroll-pos-stay', 'true');
    }, 600);
  });

  const anker = Array.from(document.querySelectorAll('[data-scroll-anker]'));
  const scrollDown = (e) => {
    setTimeout(function () {
      document.body.setAttribute('data-scroll-pos', 'is-down');
    }, 600);
    e.stopPropagation();
  };
  anker.forEach((key) => key.addEventListener('click', scrollDown));
}
