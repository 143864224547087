export const Tabs = () => {
  /**
   * <div data-tab="true">
   *   <ul role="tablist">
   *     <li id="aria-tab-nav1" data-tab-item class="is-tab-active">
   *       <button type="button" role="tab" aria-selected="true" data-tab-button aria-controls="area-tab-panel1">タブ1</button>
   *     </li>
   *     <li id="aria-tab-nav2" data-tab-item>
   *       <button type="button" role="tab" aria-selected="false" data-tab-button aria-controls="area-tab-panel2">タブ2</button>
   *     </li>
   *   </ul>
   *   <div>
   *     <div data-tab-panel id="area-tab-panel1" aria-labelledby="aria-tab-nav1" aria-hidden="false" role="tabpanel">
   *       <p>タブ1要素</p>
   *     </div>
   *     <div data-tab-panel id="area-tab-panel2" aria-labelledby="aria-tab-nav2" aria-hidden="true" role="tabpanel">
   *       <p>タブ2要素</p>
   *     </div>
   *   </div>
   * </div>
   */

  const target = Array.from(document.querySelectorAll('[data-tab-button]'));

  /**
   * タブナビの属性を変更
   */
  const listSelect = (e) => {
    const elem = e;
    elem.focus();
    const parent = elem.closest('[role="tablist"]');
    const tabItem = Array.from(parent.querySelectorAll('[role="tab"]'));

    tabItem.forEach((data) => {
      data.setAttribute('aria-selected', 'false');
      data.closest('[data-tab-item]').classList.remove('is-tab-active');
    });
    elem.closest('[aria-selected]').setAttribute('aria-selected', 'true');
    elem.closest('[data-tab-item]').classList.add('is-tab-active');
  };

  /**
   * タブ表示部分の属性を変更
   */
  const panelSelect = (e) => {
    const elem = e;
    const panel = document.getElementById(elem.getAttribute('aria-controls'));
    const parent = panel.parentNode;
    const targetPanel = Array.from(parent.querySelectorAll('[data-tab-panel]'));
    targetPanel.forEach((data) => {
      data.setAttribute('aria-hidden', 'true');
    });
    panel.setAttribute('aria-hidden', 'false');
  };

  /**
   * キーボードでタブを切り替え
   */
  const tabKeyup = (e) => {
    let elem = e.target;
    const leftArrow = 37;
    const rightArrow = 39;
    switch (e.keyCode) {
      case leftArrow:
        elem = e.target.parentNode.previousElementSibling;
        break;
      case rightArrow:
        elem = e.target.parentNode.nextElementSibling;
        break;
      default:
        break;
    }
    if (elem !== null) {
      const target = Array.from(elem.querySelectorAll('[data-tab-button]'));
      target.forEach((e) => {
        listSelect(e);
        panelSelect(e);
        ParamAppend(e);
      });
    }
  };

  /**
   * タブクリックでURLにパラメータを付与
   */
    //http://localhost:8080/products/detail.html?tab=aria-tab-nav3
  const ParamAppend = (e) => {
    const url = new URL(window.location.href);
    const q = url.searchParams.get("tab");
    const elem = e;
    const parentElem = elem.closest('[data-tab-item]');
    const dataIDElem = document.querySelectorAll('[data-tab-id]');
    let targetID;
    if (dataIDElem.length > 0) {
      targetID= parentElem.getAttribute('data-tab-id');
    } else {
      targetID= parentElem.getAttribute('id');
    }
    url.searchParams.has('tab') ? url.searchParams.set('tab',targetID) : url.searchParams.append('tab', targetID);
    window.history.pushState({}, '', url);
  }

  /**
   * ページアクセス時にパラメータがあれば該当のタブを開く
   */
  const escapeSelector = (e) => {
    //return e.replace(/[ !"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\$&')
  }
  const accessAnker = () => {
    const url = new URL(window.location.href);
    const q = url.searchParams.get("tab");
    const dataIDElem = document.querySelectorAll('[data-tab-id]');
    let elem;
    //const hash = location.hash || false;
    if (q) {
      //const elem = document.querySelector(escapeSelector(url.hash));
      if (dataIDElem.length > 0) {
        elem = document.querySelector('[data-tab-id='+`${q}`+']');
      } else {
        elem = document.querySelector(`#${q}`);
      }

      if (elem) {
        const target = Array.from(elem.querySelectorAll('[data-tab-button]'));

        target.forEach((e) => {
          listSelect(e);
          panelSelect(e);
          ParamAppend(e);
        });
        window.scroll({top: 0});
      }
    }
  }

  accessAnker();

  target.forEach((key) =>
    key.addEventListener('click', function (e) {
      e.preventDefault();
      const $target = e.currentTarget;
      listSelect($target);
      panelSelect($target);
      ParamAppend($target);
    }),
  );


  document.addEventListener('keyup', (e) => {
    tabKeyup(e);
  });

  // Network
  var elements = document.getElementsByClassName("click-to-country");

  Array.from(elements).forEach(function(element) {
    element.addEventListener('click', function () {
      const targetPanel = Array.from(document.querySelectorAll('[data-tab-button]'));
      var attribute = this.getAttribute("data-target-country");
      targetPanel.forEach((data) => {
        data.classList.remove("is-tab-active");
        data.setAttribute('aria-selected', 'false');
        if (data.getAttribute('aria-controls') == attribute) {
          data.setAttribute('aria-hidden', 'true');
          data.classList.add('is-tab-active');
          data.setAttribute('aria-selected', 'true');
        }
      });

      const targetPanel3 = Array.from(document.querySelectorAll('[data-tab-panel]'));
      var attribute = this.getAttribute("data-target-country");
      targetPanel3.forEach((data) => {
        data.setAttribute('aria-hidden', 'true');

        if (data.getAttribute('id') == attribute) {
          data.setAttribute('aria-hidden', 'false');
        }
      });
    });
  });
  // End network


}
